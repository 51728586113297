import React from "react"
import { useNavigate } from "react-router-dom"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { capitalizeFirstLetter } from "../../../../../reactor/Helpers"
import { DateString } from "../../../../../reactor/Types/Primitives"
import { Uuid } from "../../../../../reactor/Types/Primitives/Uuid"
import { NonNegativeInteger } from "../../../../../reactor/Types/Primitives/NonNegativeNumber"
import { Check } from "../../assets/Check"
import { postPartyBooking, usePartyPrice } from "../../client"
import { colors } from "../../colors"
import { usePageContext } from "../../Page"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../../../packages/localization/client-side/useLocalize"
import { Field } from "./Field"
import { usePartyContext } from "./PartyContext"
import { Breadcrumbs } from "./Breadcrumbs"
import { Footer } from "./Footer"
import { EditablePosition } from "../../../../../packages/editing/EditablePosition"
import { ImageToUrl } from "../../../../../reactor/Types/File"
import moment from "moment"

export function SummaryPage() {
    const localize = useLocalize()
    const { venue } = usePageContext()
    const locale = useCurrentLocale()
    const {
        section,
        guestCount,
        extras,
        partyPackage,
        mealOption,
        date,
        time,
        roomName,
        notes,
        nameOfTheBirthdayChild,
        email,
        acceptedTOC,
        setAcceptedTOC,
        firstName,
        lastName,
        phoneNumber,
        room,
    } = usePartyContext()
    const navigate = useNavigate()
    const partyExtras: { id: Uuid<"PartyExtra">; quantity: NonNegativeInteger }[] = []

    for (const id in extras) {
        partyExtras.push({ id: id as any, quantity: NonNegativeInteger(extras[id]) })
    }

    if (!venue) return <div>No venue active</div>

    const { data } = usePartyPrice({
        venueId: venue.id,
        numberOfChildren: NonNegativeInteger(guestCount),
        partyExtras,
        partyPackage: partyPackage?.id,
        date: moment(date).format("YYYY-MM-DD"),
        time,
    })

    if (!data) return <div>Unable to calculate price summary</div>
    const currency = data.currency

    const yearsOld = Math.round(
        new Date().getFullYear() - new Date(date?.getTime() ?? 0).getFullYear()
    )

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                marginBottom: 64,
            }}
        >
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    maxWidth: 512,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {section.summarySticker && (
                    <EditablePosition
                        obj={section}
                        xProp="summaryStickerOffsetX"
                        yProp="summaryStickerOffsetY"
                    >
                        <img src={ImageToUrl(section.summarySticker, { width: 96 })} />
                    </EditablePosition>
                )}
                <h2>
                    <EditableText
                        obj={section}
                        prop="summaryThanks"
                        defaultText="Awesome, thanks!"
                        isLocalized={true}
                    />
                </h2>
                <EditableText obj={section.summaryPage} prop="subtext" style={{ marginTop: 16 }} />
                <EditableText
                    obj={section.summaryPage}
                    prop="receiptInformation"
                    style={{ marginBottom: 16 }}
                    macros={{
                        email: <span style={{ color: colors.pinkFlirt }}>{email}</span>,
                    }}
                />
            </div>
            <Breadcrumbs page="summary" />
            <div
                style={{
                    width: "100%",
                    maxWidth: 512,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 16,
                }}
            >
                <Field section={section.summaryPage} prop="birthdayChild">
                    <OrderLine
                        primary={`${nameOfTheBirthdayChild} ${
                            yearsOld === 0 || yearsOld > 18
                                ? ""
                                : `(${yearsOld} ${localize(yearsOldPostfix)})`
                        }`}
                    />
                </Field>
                <Field section={section.summaryPage} prop="dateAndTime">
                    <OrderLine
                        primary={
                            capitalizeFirstLetter(
                                new Intl.DateTimeFormat(locale.valueOf(), {
                                    weekday: "long",
                                    year: "numeric",
                                    day: "2-digit",
                                    month: "long",
                                }).format(date)
                            ) +
                            ", " +
                            time
                        }
                    />
                </Field>
                <Field section={section.summaryPage} prop="roomAndLocation">
                    <OrderLine primary={`${roomName} - ${localize(venue.name)}`} />
                </Field>
                {!data.partyPackage && (
                    <Field section={section.summaryPage} prop="numberOfChildren">
                        <OrderLine
                            primary={`${data.numberOfChildren}`}
                            secondary={`x ${formatPrice(data.pricePerChild.valueOf())}`}
                            price={formatPrice(
                                data.numberOfChildren.valueOf() * data.pricePerChild.valueOf()
                            )}
                        />
                    </Field>
                )}
                {data.partyPackage && (
                    <Field section={section.summaryPage} prop="packageAndAddons">
                        <OrderLine
                            primary={`${localize(data.partyPackage.name)}`}
                            secondary={`${data.numberOfChildren} x ${formatPrice(data.pricePerChild.valueOf())}`}
                            price={formatPrice(data.partyPackageTotal.valueOf())}
                        />
                        {mealOption && <OrderLine primary={localize(mealOption.name)} />}
                        {data?.partyExtras.map((extra, i) => (
                            <OrderLine
                                key={i}
                                primary={localize(extra.name) ?? "??"}
                                secondary={`x ${extra.quantity}`}
                                price={formatPrice(
                                    (extra.price.valueOf() +
                                        extra.pricePerChild.valueOf() *
                                            data.numberOfChildren.valueOf()) *
                                        extra.quantity.valueOf()
                                )}
                            />
                        ))}
                    </Field>
                )}
                <Field section={section.summaryPage} prop="notes">
                    <OrderLine primary={notes ?? ""} />
                </Field>
                <div
                    style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 32 }}
                >
                    <h3 style={{ flex: 1 }}>Total</h3>
                    <h3>{formatPrice(data.totalWithVat.valueOf())}</h3>
                </div>
                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <EditableText
                        obj={section.summaryPage}
                        prop="includingVAT"
                        defaultText="Including VAT"
                        style={{ flex: 1 }}
                    />
                    <div>{formatPrice(data.vat.valueOf())}</div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", marginTop: 48 }}>
                    <CheckBox value={acceptedTOC} onChange={setAcceptedTOC} />
                    <EditableText
                        obj={section}
                        prop="iHaveReadAndAcceptTOC"
                        defaultText="I have read and accept
                    the terms and conditions"
                        isLocalized={true}
                        isMarkdown={true}
                    />
                </div>
            </div>
            <Footer
                callToAction={section.footer.confirmAndPay}
                disabled={!acceptedTOC}
                onClick={async () => {
                    try {
                        // This shouldn't happen, but just in case, and to make typescript happy
                        if (!data.partyPackage) throw new Error("Missing party package")
                        if (!email) throw new Error("Missing contact email")
                        if (!firstName) throw new Error("Missing contact first name")
                        if (!lastName) throw new Error("Missing contact last name")
                        if (!phoneNumber) throw new Error("Missing contact phone number")
                        if (!date) throw new Error("Missing date")
                        if (!room) throw new Error("Missing room")

                        const response = await postPartyBooking("Website", {
                            locale,
                            contactEmail: email,
                            contactFirstName: firstName,
                            contactLastName: lastName,
                            contactPhone: phoneNumber,
                            date: DateString(date),
                            numberOfChildren: NonNegativeInteger(guestCount),
                            roomId: room.id,
                            venueId: venue.id,
                            timeSlot: time as any,
                            additionalInfo: notes,
                            birthdayChildName: nameOfTheBirthdayChild,
                            birthdayDate: DateString(date),
                            partyPackageId: data.partyPackage.id,
                            partyMeal: mealOption?.id,
                            partyExtraIds: data.partyExtras.map((extra) => ({
                                id: extra.id,
                                quantity: extra.quantity,
                            })),
                            paymentTotal: data.totalWithVat,
                        })

                        navigate(`../confirmation/party-booking-order/${response.id}`)
                    } catch (e: any) {
                        if ("message" in e) alert(e.message)
                        else if ("detail" in e) alert(e.detail)
                        else alert("Something went wrong")
                    }
                }}
            />
        </div>
    )

    function formatPrice(price: number) {
        return new Intl.NumberFormat(locale.valueOf(), {
            currency,
            style: "currency",
            maximumFractionDigits: 0,
        }).format(price)
    }
}

const yearsOldPostfix = {
    no: "år",
    en: "years old",
    sv: "år",
    dk: "år",
    de: "Jahre alt",
}

function CheckBox({ value, onChange }: { value: boolean; onChange: (v: boolean) => void }) {
    return (
        <div
            onClick={() => onChange(!value)}
            style={{
                width: 24,
                height: 24,
                backgroundColor: value ? colors.darkPurple : "white",
                borderRadius: 4,
                border: `1px solid ${colors.darkPurple}`,
                marginRight: 12,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {value ? <Check /> : undefined}
        </div>
    )
}

function OrderLine({
    primary,
    secondary,
    price,
}: {
    primary: string
    secondary?: string
    price?: string
}) {
    return (
        <div
            style={{
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                width: "100%",
                borderRadius: 8,
                backgroundColor: "white",
                marginTop: 8,
                display: "flex",
                flexDirection: "row",
                minHeight: 48,

                alignItems: "center",
            }}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", flex: 1 }}>
                {primary}
                <div style={{ marginLeft: 8, fontSize: 14, color: colors.grey5 }}> {secondary}</div>
            </div>
            {price}
        </div>
    )
}
